import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { color, layout, compose } from "styled-system"
import MuiAccordion from "@material-ui/core/Accordion"
import MuiAccordionSummary from "@material-ui/core/AccordionSummary"
import MuiAccordionDetails from "@material-ui/core/AccordionDetails"

import css from "../../lib/styled-system/css"
import { Title } from "../atoms/Typography"
import Box from "../atoms/Box"
import Card from "../atoms/Card"
import ExpandMoreIcon from "../icons/Caret"

export const Border = styled.div`
  ${compose(color)}
  display: ${props => (props.shouldDisplay ? "none" : "block")};
  transition: all 0.01s ease-in-out;
  position: ${props => (props.fullWidthDivider ? "absolute" : "relative")};
  opacity: 0.75;
  width: 100%;
  height: 2px;
  left: 0;
  z-index: 100;
`

const Accordion = styled(MuiAccordion)`
  ${compose(layout)}
  &.MuiAccordion-root {
    background-color: transparent;
    transition: none;
    box-shadow: none;

    &.Mui-expanded {
      margin: 0px;
    }

    &:not(:last-child) {
      border-bottom: 0;
    }

    &:before {
      display: none;
    }

  & .MuiButtonBase-root, .MuiIconButton-root, .MuiAccordionSummary-expandIcon, .MuiIconButton-edgeEnd
  {
    display: flex !important;
    align-items: flex-start;

    & .Mui-expanded{
      align-items: flex-end;
    }
   }
    /* Accordion Summary */
    & .MuiAccordionSummary-root {
      &:not(.Mui-expanded) {
        ${css({ mt: 6 })};
      }
      
      ${css({ pb: 3 })};
      width: 100%;
      align-items: stretch;

      /* Default padding for mobile */
      padding-left: 6px;
      padding-right: 2px;

      /* Media Query for Desktop */
      @media (min-width: 1024px) {
        padding-left: 48px;
        padding-right: 48px;
      }

      &.Mui-expanded {
        border-bottom-width: 0px;
        ${css({ pb: 0, mb: -4 })};
      }
      & .Mui-expanded {
        ${css({ mt: 4 })};
      }
      /* Toggle Indicator Icon Button */
      & .MuiIconButton-edgeEnd {
        ${css({ mt: -4 })};

        &.Mui-expanded {
          ${css({ mt: 3 })};
        }
        /* Media Query for Desktop */
        @media (min-width: 1024px) {
          display: block; /* Reveling the button edge for desktop*/
        }
      }
    }

    /* Accordion Summary Content */
    & .MuiAccordionSummary-content {
      ${css({ m: 0, mt: -3 })};
    }

    /* Accordion Details */
    & .MuiAccordionDetails-root {
      padding: 0px;

      /* Default padding for mobile */
      padding-left: 6px;
      padding-right: 0px;

      /* Media Query for Desktop */
      @media (min-width: 1024px) {
        /* Adjust the min-width as per your theme's desktop breakpoint */
        padding-left: 48px;
        padding-right: 48px;
      }
    }

    /* Hide Expand Icon Labels */
    /* &
      .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd,
    & .MuiIconButton-label {
      ${css({ display: { _: "none !important", md: "flex" } })}
    } */
  }
`

const AccordionSummary = styled(MuiAccordionSummary)`
  ${compose(layout)}
`

const AccordionDetails = styled(MuiAccordionDetails)`
  ${compose(layout)}
`

const Accordions = ({
  label,
  items,
  toggleMany,
  expandIcon,
  labelStyles,
  contentStyles,
  card,
  variant = "h3",
  ...rest
}) => {
  const theme = useContext(ThemeContext)

  // Extract font variant color from the theme typography object
  const fontVariantColor =
    theme.typography?.[variant]?.color?.length > 3
      ? theme.typography?.[variant]?.color
      : "text"

  const [expanded, setExpanded] = React.useState("") //React.useState("panel1")
  const [expandedMany, setExpandedMany] = React.useState({}) //React.useState({panel1:true})

  const handleChange = panel => (event, newExpanded) => {
    if (toggleMany) {
      // const updatedAccordion = newExpanded ? true : false
      const newState = { ...expandedMany, [panel]: newExpanded }
      setExpandedMany(newState)
    } else {
      setExpanded(newExpanded ? panel : false)
    }
  }

  if (!items) return null

  const border = (
    <Border
      bg="border.0"
      fullWidthDivider={false}
      shouldDisplay={card?.useCard}
    />
  )

  return (
    <Box>
      {items.map(({ label, content }, idx) => {
        let id = `panel${idx + 1}`
        return (
          <Box key={id} my={card?.useCard ? 4 : 0}>
            <Card height="100%" {...rest} {...card}>
              {border}
              <Accordion
                square
                expanded={expanded === id || expandedMany[id] === true}
                onChange={handleChange(id)}
                {...rest}
              >
                <AccordionSummary
                  expandIcon={
                    expandIcon || (
                      <ExpandMoreIcon
                        width="25px"
                        height="25px"
                        strokeWidth="1.2"
                        color={fontVariantColor}
                      />
                    )
                  }
                  IconButtonProps={{
                    disableRipple: true,
                    disableTouchRipple: true,
                  }} // Disable the click ripple effect
                  aria-controls={`${id}d-content`}
                  id={`${id}d-header`}
                  style={labelStyles}
                >
                  <Title mb={3} variant={variant}>
                    {label}
                  </Title>
                </AccordionSummary>

                <AccordionDetails style={contentStyles}>
                  {content}
                </AccordionDetails>
              </Accordion>
            </Card>
          </Box>
        )
      })}
      {border}
    </Box>
  )
}

export default Accordions
