import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import Box from "../atoms/Box"

import ScrollCarousel from "../molecules/ScrollCarousel"
import FilteredGrid from "../molecules/FilteredGrid"
import SectionTitle from "../molecules/SectionTitle"
import ImageCard from "../molecules/ImageCard"
import CascadeGrid from "../molecules/CascadeGrid"

const ImageGrid = ({
  section,
  sectionTitle,
  flexGrid,
  imageCards,
  card,
  useCardFront,
  displayMode = "grid",
  imageFit,
  customImageWidth,
  customImageHeight,
  customSizeMobile,
  carouselWidth,
  carouselSpeed,
  gridType,
  cascadeGutter,
  textBackground = "solid", // enums: solid, gradient
  textVisibility = "visible", //enums: none, onHover, visible,
  alignText = "center",
  imageGridImageRatio,
  imageRatio,
  filter,
  IGFilter,
  filters,
  IGFilters,
}) => {
  const isScroll = displayMode === "scroll"
  const isGrid = !isScroll && gridType === "grid"
  const isCascade = !isScroll && gridType === "cascade"

  filter = IGFilter || filter
  filters = IGFilters || filters
  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      {isScroll && (
        <Box position="relative">
          <Box
            position={"relative"}
            width={carouselWidth === "normal" ? "100%" : "100vw"}
            ml={carouselWidth === "normal" ? undefined : "-50vw"}
            left={carouselWidth === "normal" ? undefined : "49.3%"}
          >
            <ScrollCarousel
              carouselItems={imageCards}
              logoOrImage={"image"}
              customImageWidth={imageFit === "width" ? customImageWidth : null}
              customImageHeight={
                imageFit === "height" ? customImageHeight : null
              }
              customSizeMobile={customSizeMobile}
              carouselSpeed={carouselSpeed}
            />
          </Box>
        </Box>
      )}
      {isGrid && (
        <FilteredGrid
          flexGrid={flexGrid}
          filters={filters}
          filtersStyles={{
            filterLocation: filter?.filterLocation,
            alignMenuItems: filter?.alignMenuItems,
            showFilterLabel: filter?.showFilterLabel,
            layout: filter?.layout,
            filterTextDisplay: filter?.filterTextDisplay,
            dropdownCard: filter?.dropdownCard,
          }} // only sending style fields to avoid possibly overwriting filter data
          card={{
            theme: section.theme,
            bg: section.bg,
            borderRadius: "md",
            // boxShadow: "md",
            boxShadow: "0 3px 10px rgb(0 0 0 / 30%)",
          }}
        >
          {imageCards &&
            imageCards?.map((imageCard, i) => {
              return (
                <ImageCard
                  key={i}
                  {...imageCard}
                  card={{ ...card, useCard: useCardFront }}
                  alignText={alignText}
                  columns={flexGrid.columns}
                  textVisibility={textVisibility}
                  textBackground={textBackground}
                  imageRatio={imageGridImageRatio || imageRatio}
                />
              )
            })}
        </FilteredGrid>
      )}
      {isCascade && (
        <CascadeGrid
          children={imageCards}
          flexGrid={flexGrid}
          maxWidth={section.maxWidth}
          cascadeGutter={cascadeGutter}
        />
      )}
    </Section>
  )
}

export default ImageGrid

ImageGrid.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  imageCards: PropTypes.arrayOf(PropTypes.shape(ImageCard.strapiProps)),
  useCardFront: PropTypes.bool,
  alignText: PropTypes.oneOf(["left", "center"]),
  textVisibility: PropTypes.oneOf(["none", "onHover", "visible"]),
  textBackground: PropTypes.oneOf(["solid", "gradient"]),
  ...FilteredGrid.strapiProps,
}

ImageGrid.propTypes = {
  ...ImageGrid.strapiProps,
}

export const query = graphql`
  fragment ImageGrid on STRAPI__COMPONENT_SECTIONS_IMAGE_GRID {
    strapi_id
    IMAGEGRIDSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    imageCards {
      ...ImageCard
    }
    IGFilters: filters {
      ...Filter
    }
    IGFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      filterTextDisplay
      dropdownCard {
        ...Card
      }
    }
    useCardFront
    alignText
    textVisibility
    displayMode
    imageFit
    customImageWidth
    customImageHeight
    customSizeMobile
    carouselWidth
    carouselSpeed
    gridType
    cascadeGutter
    textBackground
    imageGridImageRatio: imageRatio
  }
`
