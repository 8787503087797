import React from "react"
import { graphql } from "gatsby"

import Box from "../atoms/Box"
import Accordions from "./Accordions"
import Faq from "./Faq"
import { Title } from "../atoms/Typography"

const List = ({ title, toggleListItems, card, ...rest }) => {
  const faqItems = toggleListItems?.map(({ title, richText, faqItemRest }) => {
    return {
      label: (
        <Title textAlign="left" variant="h3" mb={3} {...faqItemRest}>
          {title}
        </Title>
      ),
      content: <Faq title={title} richText={richText} />,
    }
  })
  return (
    <Box mx={0} my={4} {...rest}>
      <Accordions items={faqItems} card={card} toggleMany={true} />
    </Box>
  )
}

export default List

export const query = graphql`
  fragment List on STRAPI__COMPONENT_MOLECULES_LIST {
    title
    toggleListItems {
      ...Faq
    }
  }
`
