import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"

import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Card from "../atoms/Card"

import FlexImageCard from "../molecules/FlexImageCard"
import SectionTitle from "../molecules/SectionTitle"
import CardCarousel from "../molecules/CardCarousel"
import FilteredGrid from "../molecules/FilteredGrid"

const ImageCards = ({
  section,
  sectionTitle,
  filter,
  ICFilter,
  filters,
  ICFilters,
  flexGrid,
  flexImageCards,
  cardCarousel,
  useCarouselFront,
  card,
  alignText,
  imageCardsAlignText,
  overlayOpacity,
  useCardFront,
  imagePosition,
  textLocation,
  imageCardsCardTitleVariant,
  cardTitleVariant,
  imageCardsCardTitleColor,
  cardTitleColor,
  imageCardsImageWidth,
  imageWidth,
  imageCardsImageRatio,
  imageRatio,
  customImageRatio,
  imageOrVideoStyle,
}) => {
  filters = ICFilters || filters
  filter = ICFilter || filter
  const throughProps = { section, sectionTitle, cardCarousel }
  if (useCarouselFront)
    return (
      <CardCarousel {...throughProps}>
        {flexImageCards &&
          flexImageCards.map((imageCard, i) => {
            return (
              <FlexImageCard
                key={i}
                {...imageCard}
                alignText={imageCardsAlignText || alignText}
                card={{ ...card, useCard: useCardFront }}
                imageOrVideoStyle={imageOrVideoStyle}
                textLocation={textLocation}
                overlayOpacity={overlayOpacity}
                cardTitle={{
                  cardTitleVariant:
                    imageCardsCardTitleVariant || cardTitleVariant,
                  cardTitleColor: imageCardsCardTitleColor || cardTitleColor,
                }}
                imagePosition={imagePosition}
                cardTitleColor={imageCardsCardTitleColor || cardTitleColor}
                imageWidth={imageCardsImageWidth || imageWidth}
                imageRatio={imageCardsImageRatio || imageRatio}
                customImageRatio={customImageRatio}
                textBackground="solid"
                textVisibility="visible"
              />
            )
          })}
      </CardCarousel>
    )

  return (
    <Section {...section}>
      {sectionTitle && (
        <Reveal>
          <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
        </Reveal>
      )}
      <FilteredGrid
        flexGrid={flexGrid}
        filters={filters}
        filtersStyles={{
          filterLocation: filter?.filterLocation,
          alignMenuItems: filter?.alignMenuItems,
          showFilterLabel: filter?.showFilterLabel,
          layout: filter?.layout,
          filterTextDisplay: filter?.filterTextDisplay,
          dropdownCard: filter?.dropdownCard,
        }} // only sending style fields to avoid possibly overwriting filter data
      >
        {flexImageCards &&
          flexImageCards.map((imageCard, i) => {
            return (
              <FlexImageCard
                key={i}
                {...imageCard}
                alignText={imageCardsAlignText || alignText}
                card={{ ...card, useCard: useCardFront }}
                imageOrVideoStyle={imageOrVideoStyle}
                textLocation={textLocation}
                overlayOpacity={overlayOpacity}
                cardTitle={{
                  cardTitleVariant:
                    imageCardsCardTitleVariant || cardTitleVariant,
                  cardTitleColor: imageCardsCardTitleColor || cardTitleColor,
                }}
                imagePosition={imagePosition}
                cardTitleColor={imageCardsCardTitleColor || cardTitleColor}
                imageWidth={imageCardsImageWidth || imageWidth}
                imageRatio={imageCardsImageRatio || imageRatio}
                customImageRatio={customImageRatio}
                textBackground="solid"
                textVisibility="visible"
              />
            )
          })}
      </FilteredGrid>
    </Section>
  )
}

export default ImageCards

ImageCards.strapiProps = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  flexImageCards: PropTypes.arrayOf(PropTypes.shape(FlexImageCard.strapiProps)),
  alignText: PropTypes.oneOf(["left", "center"]),
  overlayOpacity: PropTypes.number,
  cardTitleVariant: PropTypes.oneOf(["h2", "h3"]),
  cardTitleColor: PropTypes.oneOf(["text", "primary", "secondary"]),
  useCardFront: PropTypes.bool,
  imageWidth: PropTypes.oneOf(["full", "default"]),
  imageRatio: PropTypes.oneOf(["default", "taller"]),
}

ImageCards.propTypes = {
  ...ImageCards.strapiProps,
}

export const query = graphql`
  fragment ImageCards on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS {
    strapi_id
    IMAGECARDSSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    flexGrid {
      ...FlexGrid
    }
    card {
      ...Card
    }
    flexImageCards {
      ...FlexImageCard
    }
    imageOrVideoStyle {
      controls
      light
      playing
      loop
      volume
    }
    cardCarousel {
      ...CardCarousel
    }
    useCarouselFront
    imagePosition
    textLocation
    useCardFront
    imageCardsAlignText: alignText
    overlayOpacity
    imageCardsCardTitleVariant: cardTitleVariant
    imageCardsCardTitleColor: cardTitleColor
    imageCardsImageWidth: imageWidth
    imageCardsImageRatio: imageRatio
    ICFilters: filters {
      ...Filter
    }
    ICFilter: filter {
      alignMenuItems
      filterLocation
      showFilterLabel
      layout
      filterTextDisplay
      dropdownCard {
        ...Card
      }
    }
    customImageRatio
  }
`
