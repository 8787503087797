import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"
import { space, layout, border, compose, shadow } from "styled-system"
import { Select as MuiSelect, MenuItem } from "@material-ui/core"
import FormHelperText from "@material-ui/core/FormHelperText"

import css from "../../lib/styled-system/css"
import { splitProps, shouldForwardProp } from "../../lib/styled-system/helpers"

import InputLabel from "../atoms/InputLabel"

const Wrapper = styled("div").withConfig({ shouldForwardProp })`
  ${compose(space, layout, border, shadow)}

  & .MuiInputBase-root {
    width: 100%;
    height: 48px;
  }

  /* Select input field box styling */
  & .MuiSelect-root,
  & .MuiSelect-select,
  & .MuiSelect-select:focus {
    ${css({ fontFamily: "body", color: "text" })}
    width: 100%;
    height: 100%;
    padding: 14px 10px 8px 10px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    appearance: none; /* Remove default OS styling */
    ${css({
      fontSize: 1,
      borderRadius: "sm",
    })}
    ${border}
  }

  & .MuiInput-root.Mui-error {
    & .MuiSelect-root {
      border-color: #f44336;
    }
  }

  & .MuiSelect-icon {
    margin-right: 4px;
    /* Color the dropdown icon to match the background color*/
    ${css({ color: "text" })}
  }

  & .MuiFormHelperText-root {
    ${css({ fontFamily: "body" })}
  }
`

const StyledMuiSelect = styled(MuiSelect).withConfig({ shouldForwardProp })`
  ${compose(space, layout, border, shadow)}
  ${css({
    fontFamily: "body",
    color: "text",
  })}
`

// Customize default Paper and MenuItem styles for consistent appearance across browsers (including Edge)
const StyledPaper = styled("div")`
  && {
    border-radius: 0 !important;
    background-color: ${props => props.backgroundColor} !important;
    color: ${props => props.textColor} !important;
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    background-color: ${props => props.backgroundColor} !important;
    color: ${props => props.textColor} !important;
    &:hover {
      background-color: ${props => props.hoverBackgroundColor} !important;
      color: ${props => props.hoverTextColor} !important;
    }
  }
`

const Select = props => {
  let { space, layout, border, rest: selectProps } = splitProps(props, [
    "space",
    "layout",
    "border",
  ])

  const {
    id,
    name,
    label,
    largeLabelSize = false,
    value,
    options,
    onChange,
    onBlur,
    error,
    helperText,
    required,
    card,
    section,
    ...rest
  } = selectProps

  const theme = useContext(ThemeContext)

  const backgroundColor = card.useCard
    ? theme.colors.background[card.bg]
    : theme.themes[section.theme]?.colors?.background[section?.bg]

  const primaryBackgroundColor = card.useCard
    ? theme.themes[card.theme]?.colors?.primary
    : theme.themes[section.theme]?.colors?.primary

  const primaryTextColor = card.useCard
    ? theme.themes[card.theme]?.colors?.textOnPrimary
    : theme.themes[section.theme]?.colors?.textOnPrimary

  const textColor = card.useCard
    ? theme.themes[card.theme]?.colors?.text
    : theme.themes[section.theme]?.colors?.text

  // Pre-select the first option if value is empty or undefined
  const selectedValue =
    value === "" || value === undefined || value === null
      ? options[0]?.value
      : value

  // The renderValue function displays the selected option's label
  const renderSelectedLabel = selected => {
    const selectedOption = options.find(option => option.value === selected)
    return selectedOption ? selectedOption.label : ""
  }

  return (
    <Wrapper {...space} {...layout} {...border}>
      {label && (
        <InputLabel
          style={{ fontSize: largeLabelSize ? "15px" : "12px" }}
          htmlFor={id}
          error={error}
          required={required}
        >
          {label}
        </InputLabel>
      )}

      <StyledMuiSelect
        id={id}
        name={name}
        value={selectedValue}
        onChange={onChange}
        onBlur={onBlur}
        error={error}
        required={required}
        aria-describedby={`${id}-helper-text`}
        disableUnderline
        renderValue={renderSelectedLabel}
        MenuProps={{
          getContentAnchorEl: null, // Ensures correct alignment
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          PaperProps: {
            component: StyledPaper,
            backgroundColor: backgroundColor,
            textColor: theme.colors.text,
          },
        }}
        {...rest}
      >
        {options.map(option => (
          <StyledMenuItem
            key={option.value}
            value={option.value}
            backgroundColor={backgroundColor}
            textColor={textColor}
            hoverBackgroundColor={primaryBackgroundColor}
            hoverTextColor={primaryTextColor}
          >
            {option.label}
          </StyledMenuItem>
        ))}
      </StyledMuiSelect>

      {helperText && (
        <FormHelperText id={`${id}-helper-text`} error={error}>
          {helperText}
        </FormHelperText>
      )}
    </Wrapper>
  )
}

export default Select
