import React, { useEffect, useRef, useState } from "react"
import { graphql } from "gatsby"

import Reveal from "../utils/Reveal"
import Section from "../atoms/Section"
import Box from "../atoms/Box"
import SectionTitle from "../molecules/SectionTitle"
import List from "../molecules/List"
import FilterTabs from "../molecules/FilterTabs"

const ToggleList = ({
  section,
  sectionTitle,
  TLSectionTitle,
  listGroups,
  card,
  filterLocation,
}) => {
  const sectionTitleObj = TLSectionTitle || sectionTitle
  const anchorRef = useRef(null)

  const [listIndex, setListIndex] = useState(0)
  const [displayedList, setDisplayedList] = useState([])
  const titles = listGroups?.reduce((acc, curr) => {
    acc.push(curr.title)
    return acc
  }, [])

  useEffect(() => {
    if (listGroups?.length > 0) {
      setDisplayedList(listGroups[listIndex])
    }
  }, [listIndex, listGroups])

  const titleOnClick = index => setListIndex(index)

  return (
    <Section {...section}>
      <Reveal>
        <SectionTitle {...sectionTitleObj} />
        <Box mx={1} mt={4} mb={6}>
          <FilterTabs
            options={titles}
            anchorRef={anchorRef}
            location={filterLocation}
            selected={listGroups?.[listIndex]?.title}
            setSelected={titleOnClick}
            fromToggleList={true}
          />
        </Box>
        {displayedList && <List {...displayedList} card={card} />}
      </Reveal>
    </Section>
  )
}

export default ToggleList

export const query = graphql`
  fragment ToggleList on STRAPI__COMPONENT_SECTIONS_TOGGLE_LIST {
    strapi_id
    section {
      ...Section
    }
    TLSectionTitle: sectionTitle {
      ...SectionTitle
    }
    listGroups {
      ...List
    }
    card {
      ...Card
    }
    filterLocation
  }
`
