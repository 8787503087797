import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MorphicForm from "../integrations/MorphicForm"
import CustomHtml from "../atoms/CustomHtml"

const FormIntegration = ({
  strapi_id,
  label,
  type,
  provider,
  data,
  dataObj,
  captcha,
  card,
  section,
  successURLRedirect,
}) => {
  data = dataObj || data

  if (type !== "form") return null
  switch (provider) {
    case "morphic":
      return (
        <MorphicForm
          label={label}
          data={{
            ...data,
            integrationId: strapi_id,
            provider,
          }}
          captcha={captcha}
          successURLRedirect={successURLRedirect}
          card={card}
          section={section}
        />
      )
    case "customHtml":
    case "typeform":
      return <CustomHtml html={data?.html} />
    default:
      return null
  }
}

FormIntegration.strapiProps = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["form"]),
  provider: PropTypes.oneOf(["morphic", "customHtml", "typeform"]),
  data: PropTypes.object,
}

FormIntegration.propTypes = {
  ...FormIntegration.strapiProps,
}

export default FormIntegration

export const query = graphql`
  fragment FormIntegration on STRAPI_INTEGRATION {
    strapi_id
    label
    type
    provider
    dataObj: data
  }
`
