import React, { Suspense } from "react"
import { graphql } from "gatsby"
import { kebabCase } from "lodash"
// below used for lazy loading, temporarily disabled
// import Loadable from "@loadable/component"
import ContactForm from "../sections/ContactForm"
import CustomHtml from "../sections/CustomHtml"
import Article from "../sections/Article"
import BigImageCards from "../sections/BigImageCards"
import BlogGrid from "../sections/BlogGrid"
import ButtonGrid from "../sections/ButtonGrid"
import Careers from "../sections/Careers"
import CareersAts from "../sections/CareersAts"
import Contact from "../sections/Contact"
import Cta from "../sections/Cta"
import CtaInfoCards from "../sections/CtaInfoCards"
import DynamicTitle from "../sections/DynamicTitle"
import Faqs from "../sections/Faqs"
import ImageCards from "../sections/ImageCards"
import ImageCarousel from "../sections/ImageCarousel"
import ImageGrid from "../sections/ImageGrid"
import ImageSection from "../sections/Image"
import ImageTextSection from "../sections/ImageText/ImageTextSection"
import ImageTextCarousel from "../sections/ImageTextCarousel/ImageTextCarousel"
import InfoCards from "../sections/InfoCards"
import InteractiveInfoCards from "../sections/InteractiveInfoCards"
import LogoCarousel from "../sections/LogoCarousel"
import LogoGrid from "../sections/LogoGrid"
import NewsletterSignup from "../sections/NewsletterSignup"
import PortfolioGrid from "../sections/PortfolioGrid"
import Pricing from "../sections/Pricing"
import PricingGrid from "../sections/PricingGrid"
import QuoteCarousel from "../sections/QuoteCarousel"
import Stats from "../sections/Stats"
import TeamGrid from "../sections/TeamGrid"
import TeamMember from "../sections/TeamMember"
import Text from "../sections/Text"
import ThinCta from "../sections/ThinCta"
import Twitter from "../sections/Twitter"
import FlexColumns from "../sections/FlexColumns"
import ToggleList from "../sections/ToggleList"

export const getSectionId = section => {
  if (section.__component)
    return `sections.${section.__component}.${section.strapi_id}`
  if (section.__typename)
    return `sections.${kebabCase(section.__typename.slice(27))}.${
      section.strapi_id
    }`
  // slice(27) removes "STRAPI__COMPONENT_SECTIONS_"
  else return ""
}

const Sections = ({ section }) => {
  // used for lazy loading, temporarily disabled
  // const isSSR = typeof window === "undefined"

  let RenderedSection
  if (section.section) {
    if (section.section.hidden) {
      return null
    }
    section.section.sectionId = getSectionId(section)
  }

  switch (section.__typename || section.__component) {
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT": // Fallthrough
    case "sections.image-text":
      RenderedSection = <ImageTextSection {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE": // Fallthrough
    case "sections.image":
      RenderedSection = <ImageSection {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CTA":
    case "sections.cta":
      RenderedSection = <Cta {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CTA_INFO_CARDS":
    case "sections.cta-info-cards":
      RenderedSection = <CtaInfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_INFO_CARDS":
    case "sections.info-cards":
      RenderedSection = <InfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TWITTER":
    case "sections.twitter":
      RenderedSection = <Twitter {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BUTTON_GRID":
    case "sections.button-grid":
      RenderedSection = <ButtonGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BLOG_GRID":
    case "sections.blog-grid":
      RenderedSection = <BlogGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_NEWSLETTER_SIGNUP":
    case "sections.newsletter-signup":
      RenderedSection = <NewsletterSignup {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEAM_GRID":
    case "sections.team-grid":
      RenderedSection = <TeamGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEAM_MEMBER":
    case "sections.team-member":
      RenderedSection = <TeamMember {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_LOGO_GRID":
    case "sections.logo-grid":
      RenderedSection = <LogoGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_CAROUSEL":
    case "sections.image-carousel":
      RenderedSection = <ImageCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_STATS":
    case "sections.stats":
      RenderedSection = <Stats {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CONTACT":
    case "sections.contact":
      RenderedSection = <Contact {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CAREERS":
    case "sections.careers":
      RenderedSection = <Careers {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CAREERS_ATS":
    case "sections.careers-ats":
      RenderedSection = <CareersAts {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PRICING":
    case "sections.pricing":
      RenderedSection = <Pricing {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PRICING_GRID":
    case "sections.pricing-grid":
      RenderedSection = <PricingGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CONTACT_FORM":
    case "sections.contact-form":
      RenderedSection = <ContactForm {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_DYNAMIC_TITLE":
    case "sections.dynamic-title":
      RenderedSection = <DynamicTitle {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_LOGO_CAROUSEL":
    case "sections.logo-carousel":
      RenderedSection = <LogoCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_CUSTOM_HTML":
    case "sections.custom-html":
      RenderedSection = <CustomHtml {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_PORTFOLIO_GRID":
    case "sections.portfolio-grid":
      RenderedSection = <PortfolioGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_ARTICLE":
    case "sections.article":
      RenderedSection = <Article {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_QUOTE_CAROUSEL":
    case "sections.quote-carousel":
      RenderedSection = <QuoteCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT_CAROUSEL":
    case "sections.image-text-carousel":
      RenderedSection = <ImageTextCarousel {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_FAQS":
    case "sections.faqs":
      RenderedSection = <Faqs {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_GRID":
    case "sections.image-grid":
      RenderedSection = <ImageGrid {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS":
    case "sections.image-cards":
      RenderedSection = <ImageCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_BIG_IMAGE_CARDS":
    case "sections.big-image-cards":
      RenderedSection = <BigImageCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_INTERACTIVE_INFO_CARDS":
    case "sections.interactive-info-cards":
      RenderedSection = <InteractiveInfoCards {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TEXT":
    case "sections.text":
      RenderedSection = <Text {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_THIN_CTA":
    case "sections.thin-cta":
      RenderedSection = <ThinCta {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_FLEX_COLUMNS":
    case "sections.flex-columns":
      RenderedSection = <FlexColumns {...section} />
      break
    case "STRAPI__COMPONENT_SECTIONS_TOGGLE_LIST":
    case "sections.toggle-list":
      RenderedSection = <ToggleList {...section} />
      break
    default:
      return null
  }
  // below used for lazy loading, temporarily disabled
  // if (isSSR) return RenderedSection
  // else return <Suspense fallback={<></>}>{RenderedSection}</Suspense>
  return RenderedSection
}

export default Sections

export const query = graphql`
  fragment Sections on PageSectionsDynamicZone {
    __typename
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT {
      ...ImageTextSection
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE {
      ...ImageSection
    }
    ... on STRAPI__COMPONENT_SECTIONS_CTA {
      ...Cta
    }
    ... on STRAPI__COMPONENT_SECTIONS_TWITTER {
      ...Twitter
    }
    ... on STRAPI__COMPONENT_SECTIONS_INFO_CARDS {
      ...InfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_BUTTON_GRID {
      ...ButtonGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_BLOG_GRID {
      ...BlogGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_NEWSLETTER_SIGNUP {
      ...NewsletterSignup
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEAM_GRID {
      ...TeamGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEAM_MEMBER {
      ...TeamMember
    }
    ... on STRAPI__COMPONENT_SECTIONS_LOGO_GRID {
      ...LogoGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CAROUSEL {
      ...ImageCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_STATS {
      ...Stats
    }
    ... on STRAPI__COMPONENT_SECTIONS_CONTACT {
      ...Contact
    }
    ... on STRAPI__COMPONENT_SECTIONS_CAREERS {
      ...Careers
    }
    ... on STRAPI__COMPONENT_SECTIONS_CAREERS_ATS {
      ...CareersAts
    }
    ... on STRAPI__COMPONENT_SECTIONS_PRICING {
      ...Pricing
    }
    ... on STRAPI__COMPONENT_SECTIONS_PRICING_GRID {
      ...PricingGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_CONTACT_FORM {
      ...ContactForm
    }
    ... on STRAPI__COMPONENT_SECTIONS_DYNAMIC_TITLE {
      ...DynamicTitle
    }
    ... on STRAPI__COMPONENT_SECTIONS_LOGO_CAROUSEL {
      ...LogoCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_CUSTOM_HTML {
      ...CustomHtml
    }
    ... on STRAPI__COMPONENT_SECTIONS_PORTFOLIO_GRID {
      ...PortfolioGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_ARTICLE {
      ...Article
    }
    ... on STRAPI__COMPONENT_SECTIONS_QUOTE_CAROUSEL {
      ...QuoteCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_TEXT_CAROUSEL {
      ...ImageTextCarousel
    }
    ... on STRAPI__COMPONENT_SECTIONS_FAQS {
      ...Faqs
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_GRID {
      ...ImageGrid
    }
    ... on STRAPI__COMPONENT_SECTIONS_IMAGE_CARDS {
      ...ImageCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_BIG_IMAGE_CARDS {
      ...BigImageCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_CTA_INFO_CARDS {
      ...CtaInfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_INTERACTIVE_INFO_CARDS {
      ...InteractiveInfoCards
    }
    ... on STRAPI__COMPONENT_SECTIONS_TEXT {
      ...Text
    }
    ... on STRAPI__COMPONENT_SECTIONS_THIN_CTA {
      ...ThinCta
    }
    ... on STRAPI__COMPONENT_SECTIONS_FLEX_COLUMNS {
      ...FlexColumns
    }
    ... on STRAPI__COMPONENT_SECTIONS_TOGGLE_LIST {
      ...ToggleList
    }
  }
`
